import { ChainId, Currency } from '@gemlaunch/sdk'
import { BinanceIcon, TokenLogo } from '@gemlaunch/uikit'
import { useMemo } from 'react'
import { WrappedTokenInfo } from '@gemlaunch/token-lists'
import styled from 'styled-components'
import { useHttpLocations } from '@gemlaunch/hooks'
import { GEM } from '@gemlaunch/tokens'
import { useActiveChainId } from 'hooks/useActiveChainId'
import getTokenLogoURL from '../../utils/getTokenLogoURL'

const StyledLogo = styled(TokenLogo) <{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  const { chainId } = useActiveChainId()

  const srcs: string[] = useMemo(() => {
    if (currency?.isNative) return []
    if (currency?.isToken) {
      const tokenLogoURL = getTokenLogoURL(currency)
      if (currency instanceof WrappedTokenInfo) {
        if (!tokenLogoURL) return [...uriLocations]
        return [...uriLocations, tokenLogoURL]
      }
      if (!tokenLogoURL) return []
      return [tokenLogoURL]
    }
    return []
  }, [currency, uriLocations])

  if (currency?.isNative) {
    if (currency.chainId === ChainId.BSC) {
      return <BinanceIcon width={size} style={style} />
    }
    return <StyledLogo size={size} srcs={[`/images/chains/${currency.chainId}.png`]} width={size} style={style} />
  }


  if (currency?.isToken) {
    if (currency.address === GEM[ChainId.BSC].address || currency.address === GEM[ChainId.BSC_TESTNET].address) {
      return <StyledLogo size={size} srcs={["https://res.cloudinary.com/dgsb5totw/image/upload/v1714674287/ujk7pkkpoastlqacpukl.svg"]} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
    }
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
